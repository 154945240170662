import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import DashboardComponent from "./dashboardComponent";
import onboardingCardData from "../../utils/onboardingCardData.json";
import matchCardData from "../../utils/matchesCardData.json";
import { setUserDashboardFilters, userDashboardDetails, getUserLeagueList, getUserAirportList ,getUserAirportDepartureList,paginationFunction} from '../../store/features/userDashboard/userDashboardSlice';
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, listDashboardQueryParams } from "../../interface/userInterface";
import purchaseCardData from "../../utils/purchaseCardData.json";
import { useNavigate } from "react-router-dom";
import useWindowDimension from "../../components/hooks/useWindowDimension";
import { showToast } from "../../store/features/toast/toastSlice";
import moment from "moment";
import { updateSubscriptionPlan } from "../../store/features/auth/authSlice";
import { log } from "console";

const DashboardContainer = (props: any) => {
  const navigate = useNavigate()
  const [plan, setPlan] = useState<string | undefined>(undefined);
  const [dashboardTitleHeight, setDashboardTitleHeight] = useState(0);
  const [dashboardDescriptionHeight, setDashboardDescriptionHeight] = useState(0);
  const [filterModal, setFilterModal] = useState(false);
  const [startDate, setStartDate] = useState<any>();
  const [busy, setBusy] = useState(true);
  const [leagueOption, setLeagueOption] = useState<any>()
  const [airportOption, setAirportOption] = useState<any>()
  const [airportDeparture, setAirportDeparture] = useState<any>()
  const dimensions = useWindowDimension();
  const dispatch = useDispatch<AppDispatch>()
  const [range, setRange] = useState<[number, number] | undefined>(undefined);
  const [show, setShow] = useState(false);
  const [showFilterResult, setShowFilterResult] = useState(false);
  const [searchResult, setSearchResult] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [openUserDropdown, setOpenUserDropdown] = useState<string | null>("string");
  const [airportArrivalOption,setAirportArrivalOption] = useState<string[]>([])
  const [airportDepartureOption,setAirportDepartureOption] = useState<string[]>([]) 
  const filters = useSelector((state: any) => state?.userDashboard?.userFilters)
  const [searchQuery, setSearchQuery] = useState<string>(filters?.search || "");
  const [nonFeaturePrice,setNonFeaturePrice] = useState<{ min: number; max: number }>({ min: 0, max: 0 })
  const [featurePrice, setFeaturePrice] = useState<{ min: number; max: number }>({ min: 0, max: 0 });
  const [modalShow, setModalShow] = useState(false);
	const [planModel,setPlanModal] = useState<number | undefined>();
  const matchCardDivRef = useRef<HTMLDivElement>(null);
  const [scrollCount ,setScrollCount] = useState<number>(1)
  const isDataRef = useRef<boolean>(false)
  const countRef = useRef<any>(0)

  const authResponse = useSelector((state: any) => state?.auth?.loginResponse)
  const subscribeResponse = useSelector((state: any) => state?.auth?.subscribeResponse)
  const dashboardDetailsList = useSelector((state: any) => state?.userDashboard?.userDashboardList)
  const createAccountResponse = useSelector((state: any) => state?.auth?.createAccountResponse)
  const featuredEventsList = useSelector((state: any) => state?.userDashboard?.featuredEventsList)
  const totalNonFeaturedEvents = useSelector((state: any) => state?.userDashboard?.totalNonFeaturedEvents)
  const planSub = useSelector((state:any)=> state.auth.subscriptionPlan)
  const userInfo = useSelector((state: any) => state?.auth?.userInfo)
  const SelectPlan = useSelector((state:any) => state?.auth?.planSelect)
  console.log('SelectPlan :>> ', SelectPlan);


  //featuredEventsList
  useEffect(()=>{
    if (numberOfKeysWithValues > 0) {
      dimensions?.width > 414 && setFilterModal(true)
      setShowFilterResult(true)
    }
  },[])


  useEffect(()=>{
		if (authResponse?.data?.plan) {
      console.log('authResponse?.data?.plan', authResponse?.data?.plan)
			  const planCondition = 	authResponse?.data?.plan === "etclite" ? 1 :authResponse?.data?.plan === "etcmonthly" ? 2 : 3 
				setPlanModal(planCondition)
		}else if (userInfo?.plan) {
      console.log('userInfo?.plan', userInfo?.plan)
      const planCondition = 	userInfo?.plan === "etclite" ? 1 :userInfo?.plan === "etcmonthly" ? 2 : 3 
      setPlanModal(planCondition)
    }else {
      console.log('subscribeResponse?.data?.plan', subscribeResponse?.data?.plan)
			const planCondition = 	subscribeResponse?.data?.plan === "etclite" ? 1 :subscribeResponse?.data?.plan === "etcmonthly" ? 2 : 3 
			setPlanModal(planCondition)
		}
	},[authResponse,subscribeResponse,userInfo])

  const handlePlan = (data: "FREE" | "MONTHLY" | "ANNUALLY"): void => {
    const planData: string = data === "FREE" ? "etclite" : data === "MONTHLY" ? "etcmonthly" : "etcannual";
    setPlan(planData);
    setBusy(true)
    const planDetails={
      userId:authResponse?.data?.userId || authResponse?.data?._id ,
      plan:planData
    }
    
    // dispatch(updateSubscriptionPlan(planDetails)).then((resultAction: any) => {
    //   if (userDashboardDetails.fulfilled.match(resultAction)) {
    //     dispatch(
    //       showToast({
    //         message: (resultAction as any)?.payload?.message || "",
    //         type: "success",
    //       })
    //     );
    //   }else if (userDashboardDetails.rejected.match(resultAction)) {
    //     dispatch(
    //       showToast({
    //         message: (resultAction as any)?.payload?.message,
    //         type: "error",
    //       })
    //     );
    //   }
    // });
    setBusy(false)
    setModalShow(false)
  };

  useEffect(() => {
    if (filters?.departureDate) {
      setStartDate(filters?.departureDate)
    }
    if (filters?.totalPrice?.length > 0) {
      setRange(filters?.totalPrice)
    }
  }, [])

  const onFilter = () => {
    setShow(!show)
    const newFilterData = {}
    filterModal && dispatch(setUserDashboardFilters(newFilterData))
    filterModal && setScrollCount(1)
    setShowFilterResult(show ? false : true)
    setFilterModal(!filterModal)
    filterModal && setStartDate("")
  }

  const handleClose = () => {
    setShow(!show);
    setFilterModal(!filterModal);
    setShowFilterResult(true)
  }

  const handleShow = () => {
    setShow(true)
  };

  const handleNavigate = (id: number) => {
    navigate(`/dashboard/events/details/${id}`)
  }

  /**
 * useEffect hook to calculate heights of dashboard elements.
 * Sets heights for dashboard title, description, and header container.
 */
  useEffect(() => {
    const dashboardTitleHeightElement = document.getElementsByClassName("dashboardTitle")[0] as HTMLElement;
    const dashboardDescriptionHeightElement = document.getElementsByClassName("dashboardDescription")[0] as HTMLElement;
    const headerContainerHeightElement = document.getElementsByClassName("headerContainer")[0] as HTMLElement;

    if (dashboardTitleHeightElement) {
      setDashboardTitleHeight(dashboardTitleHeightElement.offsetHeight);
    }
    if (dashboardDescriptionHeightElement) {
      setDashboardDescriptionHeight(dashboardDescriptionHeightElement.offsetHeight);
    }
    if (headerContainerHeightElement) {
      setDashboardTitleHeight(headerContainerHeightElement.offsetHeight);
    }

  }, []);

  const handleFilter = (option: String[]) => {
    const newFilterData = {
      ...filters,
      airportArrival: option,
    }
    setScrollCount(1)
    dispatch(setUserDashboardFilters(newFilterData))
    setClearFilter(false)
  }

  const typeFilter = (option: String) => {
    const newFilterData = {
      ...filters,
      leagueName: option
    };
    setScrollCount(1)
    dispatch(setUserDashboardFilters(newFilterData));
    setClearFilter(false)
  };

  const locationFilter = (option: String[]) => {
    const newFilterData = {
      ...filters,
      airportDeparture: option,
    }
    setScrollCount(1)
    dispatch(setUserDashboardFilters(newFilterData))
    setClearFilter(false)

  }

  const removeDate = () => {
    const newFilterData = {
      ...filters,
      departureDate: ""
    }
    setScrollCount(1)
    dispatch(setUserDashboardFilters(newFilterData))
  }


  const formatArrayToString = (array: any) => {
    // Check if array is not empty and is an array
    if (array && Array.isArray(array)) {
      return array.join(",");
    }
    else {
      return "";
    }
  };


  const getUserList = () => {
     setBusy(true)
    const queryParams: listDashboardQueryParams = {
      search: filters?.search?.replace(/vs\./, "vs") || "",
      airportDeparture: formatArrayToString(filters?.airportDeparture),
      airportArrival: formatArrayToString(filters?.airportArrival),
      totalPrice: formatArrayToString(filters?.totalPrice),
      leagueName: formatArrayToString(filters?.leagueName),
      departureDate: filters?.departureDate || "",
      page: scrollCount,
      limit: 20,
    };
    dispatch(paginationFunction({page: scrollCount, limit: 20}))
    dispatch(userDashboardDetails(queryParams)).then((resultAction: any) => {
      setBusy(false);
      if (userDashboardDetails.fulfilled.match(resultAction)) {
        if(scrollCount * 20 <= resultAction?.payload?.data?.totalNonFeaturedEvents){
          countRef.current = resultAction?.payload?.data?.totalNonFeaturedEvents
          isDataRef.current = true
         }else {
          isDataRef.current = false
         }
        setSearchResult(!!filters?.search)
      } else if (userDashboardDetails.rejected.match(resultAction)) {
        dispatch(
          showToast({
            message: resultAction?.error?.message || "",
            type: "error",
          })
        );
      }
    });
  }

  useEffect(() => {
    getUserList()
  }, [JSON.stringify(filters),scrollCount])

  useEffect(() => {
    dispatch(getUserLeagueList()).then((res: any) => {
      if (getUserLeagueList.fulfilled.match(res)) {
        setLeagueOption(res?.payload?.data)
      }
    })
  }, [])

  useEffect(()=>{
    dispatch(getUserAirportDepartureList()).then((res: any) => {
      if (getUserAirportDepartureList.fulfilled.match(res)) {
        setAirportDeparture(res?.payload?.data)
      }
    })
  },[])

  useEffect(() => {
    dispatch(getUserAirportList()).then((res: any) => {
      setAirportOption(res?.payload?.data)
    })
  }, [])

  useEffect(()=>{
    if (airportOption) { 
      const airportArrivalOp = airportOption?.filter((item:any) => !filters?.airportDeparture?.includes(item?.destinationAirport));
      setAirportArrivalOption(airportArrivalOp)
    }
  },[filters?.airportDeparture,airportOption])


  useEffect(()=>{
    if (airportDeparture) { 
      const airportDepartureOp = airportDeparture?.filter((item:any) => !filters?.airportArrival?.includes(item?.departingAirport));
      setAirportDepartureOption(airportDepartureOp)
    }
  },[filters?.airportArrival,airportDeparture])


  //search functionality 
  const handleSearch = (searchQuery: string) => {
    const newFilters = { ...filters, search: searchQuery }
    setScrollCount(1)
    dispatch(setUserDashboardFilters(newFilters))
    setClearFilter(false)
  }

  const handleRange = (e: [number,number]) => {
    setRange(e)
  }


  // price debounce set values
  useEffect(() => {
    const timeout = setTimeout(() => {
      const newFilters = { ...filters, totalPrice: range ,page:1};
      setScrollCount(1)
      dispatch(setUserDashboardFilters(newFilters));
      setClearFilter(false)
    }, 1000);

    return () => clearTimeout(timeout);
  }, [range]);



  const formatDateString = (date: any) => {
    if (date !== undefined && date !== null && typeof date !== 'string') {
      return moment(date).format('YYYY-MM-DD')
    }
  };



  // set startDate formate as need to pass in backend 
  useEffect(() => {
    const formattedStartDate = startDate && formatDateString(startDate);
    if (formattedStartDate) {
      const newFilterData = {
        ...filters,
        departureDate: formattedStartDate
      };
      setScrollCount(1)
      dispatch(setUserDashboardFilters(newFilterData));
      setClearFilter(false)
    }
  }, [startDate])



  // Calculate minimum and maximum ticket prices for non-featured and featured events
  useEffect(()=>{
     // Calculate min and max ticket prices for non-featured events
    if (dashboardDetailsList?.length) {
      const maxFind =dashboardDetailsList &&  dashboardDetailsList?.map((data:any)=>{
        return data?.flightList?.[0]?.totalPrice
      }) 
      const filteredValues =maxFind &&  maxFind?.filter((value:any) =>  (value !== undefined && value !== null ));
      const maxTotalPriceObject = filteredValues && Math.max(...filteredValues);
      const minTotalPriceObject = filteredValues && Math.min(...filteredValues);
      setNonFeaturePrice({ min: minTotalPriceObject, max: maxTotalPriceObject });
    }
    // Calculate min and max ticket prices for featured events
    if (featuredEventsList?.length) {
      const maxFind =featuredEventsList &&  featuredEventsList?.map((data:any)=>{
        return data?.flightList?.[0]?.totalPrice
      }) 
      const filteredValues =maxFind &&  maxFind?.filter((value:any) => value !== undefined && value !== null);
      const maxTotalPriceObject = filteredValues &&Math.max(...filteredValues);
      const minTotalPriceObject = filteredValues &&Math.min(...filteredValues);
      setFeaturePrice({ min: minTotalPriceObject, max: maxTotalPriceObject });
    }
  },[])

  /**
 * Count the number of non-empty values in an object.
 * @param obj The object to count non-empty values from.
 * @returns The count of non-empty values.
 */
  const countNonEmptyValues = (obj: { [key: string]: any }): number => {
    return  obj && Object.values(obj).filter(value =>
      (Array.isArray(value) && value.length > 0) ||
      (typeof value === 'string' && value.trim() !== '') ||
      (typeof value === 'object' && Object.keys(value).length > 0)
    ).length;
  };
  
  const { search ,...rest } = filters || {};
  const numberOfKeysWithValues = countNonEmptyValues(rest);
  
  

  // refresh icon click that logic 
  const refreshClick = () => {
    const queryParams = {
      search: filters?.search || "",
      airportDeparture:"" ,
      airportArrival: "",
      totalPrice: "",
      leagueName: "",
      departureDate:  "",
      page: "",
      limit: "",
    };
    // const newFilterData = {}
    dispatch(setUserDashboardFilters(queryParams))
    setScrollCount(1)
    setStartDate("")
    setClearFilter(true)
    // setSearchQuery("")
    setRange(undefined)
  }


  //remove airportArrival
  const handleRemoveArrival = (val: string) => {
    const filterDataRemove = filters?.airportArrival?.filter((item: string) => item !== val)
    const newFilterData = {
      ...filters,
      airportArrival: filterDataRemove,
    }
    setScrollCount(1)
    dispatch(setUserDashboardFilters(newFilterData))
    setOpenUserDropdown(null)
  }


  // remove league
  const handleRemoveLeague = (val: string) => {
    const filterDataRemove = filters?.leagueName?.filter((item: string) => item !== val)
    const newFilterData = {
      ...filters,
      leagueName: filterDataRemove
    }
    setScrollCount(1)
    dispatch(setUserDashboardFilters(newFilterData))
    setOpenUserDropdown(null)
  }

  //remove airportDeparture
  const handleRemoveDeparture = (val: string) => {
    const filterDataRemove = filters?.airportDeparture?.filter((item: string) => item !== val)
    const newFilterData = {
      ...filters,
      airportDeparture: filterDataRemove,
    }
    setScrollCount(1)
    dispatch(setUserDashboardFilters(newFilterData))
    setOpenUserDropdown(null)
  }


  //remove price filter
  const handleRemovePrice = () => {
    const newFilterData = {
      ...filters,
      totalPrice: []
    }
    setScrollCount(1)
    dispatch(setUserDashboardFilters(newFilterData))
    setOpenUserDropdown(null)
    setRange(undefined)
  }


  //remove date filter
  const handleRemoveDate = () => {
    const newFilterData = {
      ...filters,
      departureDate: "",
    }
    setScrollCount(1)
    dispatch(setUserDashboardFilters(newFilterData))
    setStartDate("")
    setOpenUserDropdown(null)
  }


  useEffect(() => {
    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

     // Remove scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollCount]);



/**
 * Handle scroll event to load more data when the user reaches the bottom of the page.
 */
  const handleScroll = () => { 
    if (
       (matchCardDivRef.current &&
      window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight && isDataRef.current && scrollCount * 20 <= (countRef.current ||totalNonFeaturedEvents))
    ) {
      setScrollCount(scrollCount + 1);
    }
  };

  /**
 * Calculates the minimum ticket price for both featured and non-featured events.
 * @returns The minimum ticket price.
 */
  const  handlePriceMekar = ()=>{
    if (featurePrice?.min && nonFeaturePrice?.min) {
      return Math.min(featurePrice?.min ,nonFeaturePrice?.min) || 0
    }else  if(featurePrice?.min){
      return featurePrice?.min
    }else if (nonFeaturePrice?.min){
      return nonFeaturePrice?.min
    }
  }


  return (
    <>
      <Helmet>
        <title>Dashboard | Triptix - Best Offers For European Matches & Flights</title>
        <meta name="description" content="Explore featured events, travel offers, and upcoming fixtures across Europe!" />
      </Helmet>
      <DashboardComponent
        onboardingCardData={onboardingCardData?.onboardingCardData}
        matchCardData={matchCardData.matchCardData}
        dashboardTitleHeight={dashboardTitleHeight}
        dashboardDescriptionHeight={dashboardDescriptionHeight}
        filterModal={filterModal}
        onFilter={onFilter}
        handleFilter={handleFilter}
        locationFilter={locationFilter}
        typeFilter={typeFilter}
        startDate={startDate}
        setPlan={setPlan}
        handlePlan={handlePlan}
        plan={plan}
        purchaseCardData={purchaseCardData.purchaseCardData}
        handleNavigate={handleNavigate}
        dimensions={dimensions}
        show={show}
        setShow={setShow}
        handleShow={handleShow}
        leagueOption={leagueOption}
        airportOption={airportArrivalOption}
        dashboardDetails={dashboardDetailsList}
        handleSearch={handleSearch}
        handleRange={handleRange}
        range={range}
        setStartDate={setStartDate}
        handleClose={handleClose}
        userDashboard
        authResponse={authResponse}
        refresh="refresh"
        showFilterResult={showFilterResult}
        removeDate={removeDate}
        busy={busy}
        subscribeResponse={subscribeResponse}
        filters={filters}
        searchResult={searchResult}
        maxTotalPriceObject={Math.max(featurePrice?.max ,nonFeaturePrice?.max) || 500}
        minTotalPriceObject={handlePriceMekar() || 0}
        user
        numberOfKeysWithValues={numberOfKeysWithValues}
        refreshClick={refreshClick}
        handleRemoveArrival={handleRemoveArrival}
        handleRemoveLeague={handleRemoveLeague}
        handleRemoveDeparture={handleRemoveDeparture}
        handleRemovePrice={handleRemovePrice}
        handleRemoveDate={handleRemoveDate}
        clearFilter={clearFilter}
        dashboard
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        openUserDropdown={openUserDropdown}
        setOpenUserDropdown={setOpenUserDropdown}
        airportDepartureOption={airportDepartureOption}
        dateClass ="date-picker-class"
        modalShow={modalShow}
        setModalShow={setModalShow}
        createAccountResponse={createAccountResponse}
        planModel={planModel}
        matchCardDivRef={matchCardDivRef}
        scrollCount={scrollCount}
        featuredEventsList={featuredEventsList}
        userInfo = {userInfo}
        SelectPlan={SelectPlan}
      />
    </>
  );
};

export default DashboardContainer;

