import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import {
	signUpParams,
	// signUpErrorMessage,
	signUpuserData,
	forgotData,
	forgotErrorMessage,
	forgotParams,
	// loginData,
	loginErrorMessage,
	loginParams,
	resetData,
	resetErrorMessage,
	resetParams,
	resendOtpData,
	resendOtpErrorMessage,
	socialLoginParams,
	subscribeParams,
	createAccountParams,
	subscribeErrorMessage,
	createAccountErrorMessage,
} from "../../../interface/authInterface";
import authServices from "../../features/auth/authService";

export const loginUser = createAsyncThunk(
	"/login",
	async (data: loginParams) => {
			const res = await authServices.login(data);
		 return res;	
		} 
);

export const subscribe = createAsyncThunk(
	"/subscribe",
	async (data: subscribeParams) => {
		const res = await authServices.subscribe(data);
		return res;
	}
)

export const createAccount = createAsyncThunk(
	"/createAccount",
	async (data: createAccountParams) => {
		const res = await authServices.createAccount(data);
		return res;
	}
)

export const socialLogin = createAsyncThunk(
	"/socialLogin",
	async (data: socialLoginParams,{ rejectWithValue }) => {
		try {
			const res = await authServices.socialLogin(data);
			return res;
		} catch (error) {
			return rejectWithValue(error)
		}
	}
);

export const forgotPassword = createAsyncThunk(
	"/forgot",
	async (data: forgotParams) => {
		const res = await authServices.forgot(data);
		return res;
	}
);

export const resetPassword = createAsyncThunk(
	"/reset",
	async (data: resetParams) => {
		const res = await authServices.reset(data);
		return res;
	}
);

export const signUp = createAsyncThunk(
	"/signUp",
	async (data: signUpParams, { rejectWithValue }) => {
		try {
			const res = await authServices.signUp(data);
			return res;
		} catch (error) {
			return rejectWithValue(error)
		}
	}
);

export const resendOTP = createAsyncThunk(
	"/resendOtp",
	async () => {
		const res = await authServices.resendOtp();
		return res;
	}
);

export const verifyOTP = createAsyncThunk(
	"/verifyOtp",
	async (queryParams : any) => {
		const res = await authServices.verifyOTP(queryParams);
		return res;
	}
);

export const updateSubscriptionPlan = createAsyncThunk("/auth/updateSubscription",async(planData:any)=>{
  const res = await authServices.updateSubscriptionPlan(planData)
  return res
})

export const updateProfile = createAsyncThunk("/updateProfile",async(data: createAccountParams)=>{
	const res = await authServices.updateProfile(data)
  return res;
})

export const getUserDetails = createAsyncThunk("/auth/getUserDetails",async(data:any)=>{
const res = await authServices.getUserDetails(data)
return res
})

// Define the initial state type
export interface AuthState {
	loginResponse: any;
	loginErrorMessage: loginErrorMessage; 
	subscribeResponse: AxiosResponse;
	subscribeErrorMessage: subscribeErrorMessage;
	createAccountResponse: AxiosResponse;
	createAccountErrorMessage: createAccountErrorMessage;
	forgotResponse: AxiosResponse<string, forgotData>;
	forgotErrorMessage: forgotErrorMessage; 
	resetResponse: AxiosResponse<string, resetData>;
	resetErrorMessage: resetErrorMessage; 
	signUpResponce: AxiosResponse<string, signUpuserData>;
	signUpErrorMessage: any;
	resendOtpResponce: AxiosResponse<string, resendOtpData>;
	resendOtpErrorMessage: resendOtpErrorMessage;
	isLoading: boolean;
	social:boolean;
	otpVerify: any;
	otpFlag:boolean;
	subscriptionPlan:any,
	isImported: boolean,
	isOnBoarded: boolean,
	userName:string,
	userInfo:any,
	planSelect:string
}


/**
 * Creates a slice for authentication-related state management using Redux Toolkit.
 * @param {string} name - The name of the slice.
 * @param {object} initialState - The initial state of the slice.
 * @returns None
 */
const authSlice : any = createSlice({
	name: "auth",
	initialState: {
		loginResponse: {},
		loginErrorMessage: {},
		subscribeResponse: {},
		subscribeErrorMessage: {},
		createAccountResponse: {},
		createAccountErrorMessage: {},
		forgotResponse: {},
		forgotErrorMessage: {},
		resetResponse: {},
		resetErrorMessage: {},
		signUpResponce: {},
		signUpErrorMessage: {},
		isLoading: false,
		resendOtpResponce: {},
		resendOtpErrorMessage: {},
		social:false,
		otpVerify:0,
		otpFlag:false,
		subscriptionPlan:"",
		isImported:false,
		userInfo:{},
		planSelect:""
	} as AuthState,
	reducers: {
		logout:(state,action)=>{
			state.loginResponse = action.payload
			state.createAccountResponse = action.payload
		},
		setCheckBeehive:(state,action)=>{
			state.loginResponse = action.payload
		},
		setLoginResponse:(state,action)=>{
			state.subscribeResponse = action.payload
		},
		setSocialAccount:(state,action)=>{
			state.social=false
		},
		removeOtp:(state,action)=>{
			state.otpVerify=action.payload
		},
		handleOtpFlag:(state,action)=>{
			state.otpFlag=action.payload
		},
		handlePlanSelect:(state,action)=>{
			state.subscriptionPlan = action?.payload
		},
		handleIsImported:(state,action)=>{
			state.isImported = action?.payload
		},
		handleOnboarding:(state,action)=>{
			state.userInfo = action?.payload || {}
		},
		handlePlanSelectGlobal :(state,action)=>{
			state.planSelect = action?.payload 
		},
	},
	extraReducers: (builder) => {
		builder
		.addCase(loginUser.pending, (state) => { })
		.addCase(loginUser.fulfilled, (state, action) => {
			// state.loginResponse = action.payload;
			const { isOnBoarded ,plan,isImported} = action?.payload?.data || {};
			if (!isOnBoarded) {
				// If isOnBoarded is false, do not store the plan
				state.loginResponse = {
					...action.payload,
					data: {
						...action.payload?.data,
						plan: undefined // Do not store the plan
					}
				};
			} else {
				// If isOnBoarded is true, store the plan
						state.loginResponse = action.payload;
						console.log('233', plan)
						state.planSelect = plan
				}
				state.isImported = isImported;
		})
		.addCase(loginUser.rejected, (state, action) => {
			console.log('action :>> ', action);
			state.loginErrorMessage = action.error;
		});
		builder
		.addCase(subscribe.pending, () => {})
		.addCase(subscribe.fulfilled, (state, action) => {
			state.subscribeResponse = action.payload;
			console.log('245', action?.payload?.data?.plan)
			state.planSelect = action.payload?.data?.plan
		})
		.addCase(subscribe.rejected, (state, action) => {
			state.subscribeErrorMessage = action.error;
		});	
		builder
		.addCase(createAccount.pending, () => {})
		.addCase(createAccount.fulfilled, (state, action) => {
			state.createAccountResponse = action.payload;
		})
		.addCase(createAccount.rejected, (state, action) => {
			state.createAccountErrorMessage = action.error;
		});	
		builder
		.addCase(socialLogin.pending, (state) => { })
		.addCase(socialLogin.fulfilled, (state, action) => {
			const { isOnBoarded ,plan} = action?.payload?.data || {};
			if (!isOnBoarded) {
				// If isOnBoarded is false, do not store the plan
				state.loginResponse = {
					...action.payload,
					data: {
						...action.payload?.data,
						plan: undefined // Do not store the plan
					}
				};
				state.otpFlag=true
			} else {
				// If isOnBoarded is true, store the plan
						state.loginResponse = action.payload;
						console.log('275', plan)
						state.planSelect = plan
					}
					state.social = true;
				})
				.addCase(socialLogin.rejected, (state, action) => {
					state.loginErrorMessage = action.error;
					state.social = false
				});
				builder
				.addCase(forgotPassword.pending, (state) => { })
				.addCase(forgotPassword.fulfilled, (state, action) => {
					state.forgotResponse = action.payload;
				})
				.addCase(forgotPassword.rejected, (state, action) => {
					state.forgotErrorMessage = action.error;
				});
				builder
				.addCase(resetPassword.pending, (state) => { })
				.addCase(resetPassword.fulfilled, (state, action) => {
					state.resetResponse = action.payload;
				})
				.addCase(resetPassword.rejected, (state, action) => {
					state.resetErrorMessage = action.error;
				});
		builder
			.addCase(signUp.pending, (state) => { })
			.addCase(signUp.fulfilled, (state, action) => {
				state.loginResponse = action.payload;
				state.otpVerify = action?.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.signUpErrorMessage = action.error;
			});
		builder
			.addCase(verifyOTP.pending, (state) => { })
			.addCase(verifyOTP.fulfilled, (state, action) => {
				state.signUpResponce = action.payload;
				state.otpFlag=true
			})
			.addCase(verifyOTP.rejected, (state, action) => {
				state.signUpErrorMessage = action.error;
			});
		builder
			.addCase(resendOTP.pending, (state) => { })
			.addCase(resendOTP.fulfilled, (state, action) => {
				state.resendOtpResponce = action.payload;
			})
			.addCase(resendOTP.rejected, (state, action) => {
				state.resendOtpErrorMessage = action.error;
			});
			builder
			.addCase(updateSubscriptionPlan.pending, (state,action) => {})
			 .addCase(updateSubscriptionPlan.fulfilled, (state,action) => {
				state.loginResponse = action?.payload
				state.subscribeResponse = action?.payload
				console.log('330', action?.payload?.data?.plan)
				state.planSelect = action?.payload?.data?.plan
			 }).addCase(updateSubscriptionPlan.rejected, (state,action) => {})
			 builder
			 .addCase(updateProfile.pending, (state,action) =>{})
			 .addCase(updateProfile.fulfilled, (state,action) => {
				state.createAccountResponse= action?.payload
       }).addCase(updateProfile.rejected, (state,action) => {})
			 builder
			 .addCase(getUserDetails.pending, (state,action) =>{})
			 .addCase(getUserDetails.fulfilled, (state,action) => {
        state.subscribeResponse = action?.payload
				state.loginResponse = action?.payload
				console.log('342', action?.payload?.data?.plan)
				state.planSelect = action?.payload?.data?.plan
       }).addCase(getUserDetails.rejected, (state,action) => {})
	},
});
export const { logout,setLoginResponse,setSocialAccount,removeOtp ,handleOtpFlag,handlePlanSelect,handleIsImported,handleOnboarding,setCheckBeehive,handlePlanSelectGlobal} = authSlice.actions;
export default authSlice.reducer;
